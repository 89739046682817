import * as _ from "lodash-es"
import { toast } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query"

import { fetchJson } from "utils/network"

type TData = undefined
type TError = unknown
type TVariables = number
type THook = (
  options?: UseMutationOptions<TData, TError, TVariables>
) => UseMutationResult<TData, TError, TVariables>

const deleteCertificateRequest = (certificateId: TVariables) =>
  // @ts-ignore
  fetchJson<TData>(`Zeugnisse/DeleteCertificateRequest/${certificateId}`, { method: "DELETE" })

export const useDeleteCertificateRequest: THook = (options) => {
  const { t } = useTranslation("toastComponent")

  let toastId: ReturnType<typeof toast.loading>

  return useMutation(deleteCertificateRequest, {
    onMutate(...args) {
      toastId = toast.loading(t("inProgress"))

      const { analytics } = window

      if (analytics) {
        void analytics.track("Certificate Request deleted", {
          certificateId: args[0],
        })
      }

      options?.onMutate?.(...args)
    },
    onError(...args) {
      toast.error(t("error"), { id: toastId })

      void options?.onError?.(...args)
    },
    onSuccess(...args) {
      toast.success(t("done"), { id: toastId })

      void options?.onSuccess?.(...args)
    },
    ..._.omit(options, ["onMutate", "onError", "onSuccess"]),
  })
}
