/* eslint-disable import/order */
import { useAccount, useMsal } from "@azure/msal-react"
import axios, { AxiosError } from "axios"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"

import SelectGeneric from "@/components/forms/SelectGeneric"
import {
  RadioGroup,
  RadioItem,
} from "@/components/ui/radio-group"
import { Upload as UploadIcon, Logout as LogoutIcon } from "assets/icons"
import Button from "components/elements/Button"
import { Drawer } from "components/elements/Drawer"
import Select from "components/forms/Select"
import TextInput from "components/forms/TextInput"
import Toggle from "components/forms/Toggle"
import { getMostPriviligedRole } from "components/pages/user-management/UserCard"
import { useCurrentUserContext } from "context/CurrentUserContext"
import {
  useCurrentUserMutation,
  useFunctions,
  useCertificateLanguages,
  useUsers,
  employeeKeys,
  useLogoutUser,
  useFuntionSelectItemsSortedAsc,
  useResetPassword,
  certificateKeys,
  useCertificateMutation,
  ICertificate,
  EUserRoleCodes,
} from "hooks/data"
import { userKeys } from "hooks/data/user/keys"
import { EGenders, LOCAL_STORAGE_KEYS } from "src/constants"
import { IUser } from "types"
import { apiUrl, fetchBlob, isDevEnv, isTestEnv } from "utils/network"

import PasswordModal from "../pages/PasswordModal"
import UserSelectOrgUnit from "../pages/user-management/drawers/UserSelectOrgUnit"

type TProps = {
  show: boolean
  onClose: () => void
  profilePicture?: string | null
}

type TFormValues = {
  email?: IUser["Email"]
  firstName?: IUser["Vorname"]
  lastName?: IUser["Nachname"]
  roles?: IUser["SysRoles"]
  UILanguage?: IUser["CatSprache"]["ID"]
  function?: IUser["CatFunktionID"]
  responsibleHR?: number
  currentPassword?: string
  newPassword?: string
}

export default function ProfileDrawer(props: TProps): JSX.Element {
  const { show, onClose } = props

  const queryClient = useQueryClient()
  const router = useRouter()
  const { t, i18n } = useTranslation("profileDrawer")
  const { t: tN } = useTranslation(["userRoles", "shared", "forms"])

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})

  const { register, getValues, setValue, setError, reset, formState } =
    useForm<TFormValues>({ mode: "onChange" })
  const { errors } = formState

  const { currentUser, UILanguage, isHr } = useCurrentUserContext()
  const logoutUser = useLogoutUser({
    onSuccess() {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES)
      localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_EMPLOYEES)
      localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_USERS)
      queryClient.clear()

      if (isTestEnv || isDevEnv) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN)
        delete axios.defaults.headers.common.Authorization
      }

      void router.push("/public/login")
    },
  })

  const [substitution, setSubstitution] = useState(false)

  const certificateLanguagesQuery = useCertificateLanguages({ returnAll: true })
  const functionsQuery = useFunctions({ $filter: "IsDeactivated eq false" })
  const usersQuery = useUsers()
  const updateCertificate = useCertificateMutation()

  const mostPriviligedRole = getMostPriviligedRole(currentUser?.SysRoles)

  const [passwordModalOpen, setPasswordModalOpen] = useState(false)

  useEffect(() => {
    if (currentUser?.IsStellvertreterVerantwHrAktiv) {
      setSubstitution(true)
    }
  }, [currentUser])

  const currentUserMutation = useCurrentUserMutation()
  const resetPassword = useResetPassword({
    onError(error_) {
      const error = error_ as AxiosError<{ Message?: string }>
      if (
        error.response?.status === 400 &&
        error.response?.data.Message === "WRONG_PASSWORD"
      ) {
        setError("currentPassword", {
          message: `${tN("forms:wrongCurrentPassword")}`,
        })
      }
    },
    onSuccess() {
      reset({
        currentPassword: "",
        newPassword: "",
      })
    },
  })

  useEffect(() => {
    const { firstName, lastName } = getValues()
    if (currentUser && !firstName && !lastName) {
      reset({
        email: currentUser.Email,
        firstName: currentUser.Vorname,
        lastName: currentUser.Nachname,
        responsibleHR: currentUser.StellvertreterVerantwHrBenutzer?.ID,
      })
    }
  }, [currentUser, getValues, reset])

  async function updateUser(payload: Partial<IUser>) {
    if (currentUser) {
      return currentUserMutation.mutateAsync({
        ...currentUser,
        ...payload,
      })
    }
  }

  async function onProfilePictureSelect(files: FileList | null) {
    if (!files) return

    const image = files[0]

    const formData = new FormData()
    formData.append("0", image)

    const imageId = await fetchBlob<string>("file/post", {
      method: "POST",
      body: formData,
    })

    void updateUser({
      ProfileFileId: imageId,
    })
  }

  async function onLogoutButtonClick() {
    if (account) {
      await instance.logoutPopup({
        account,
        mainWindowRedirectUri: "/public/login",
      })
    } else {
      logoutUser.mutate()
    }

    const { analytics } = window

    if (analytics) {
      void analytics.track("User signed out")
    }
  }

  const functionSelectItems = useFuntionSelectItemsSortedAsc({
    language: UILanguage,
    data: functionsQuery.data?.filter(({ IsDeactivated }) => !IsDeactivated),
    gender: currentUser?.CatGeschlechtID,
  })

  const languagesOptions = certificateLanguagesQuery.data?.map(({ CatSpracheID, Bezeichnung }) => ({
    value: CatSpracheID,
    primary: Bezeichnung,
  })) ?? []

  const substitutionOptions = usersQuery.data?.map(
    ({ BenutzerID, Vorname, Nachname }) => ({
      value: BenutzerID,
      primary: `${Vorname} ${Nachname}`,
    })) ?? []

  function updateUsersCache(user: IUser) {
    const usersQueryData = queryClient.getQueryData<IUser[]>(userKeys.all())
    if (usersQueryData) {
      queryClient.setQueryData(
        userKeys.all(),
        usersQueryData.map((user_) =>
          user_.BenutzerID === user.BenutzerID ? user : user_
        )
      )
    }
  }

  function updateCertificateInView(user: IUser) {
    const match = /^\/certificates\/(\d+)/.exec(router.asPath)
    const certificateInView = match ? Number(match[1]) : null

    if (certificateInView) {
      const certificate = queryClient.getQueryData<ICertificate>(
        certificateKeys.one(certificateInView)
      )

      if (certificate) {
        const userJob = functionsQuery.data?.find(
          ({ CatFunktionID }) => CatFunktionID === user.CatFunktionID
        )
        const userJobTitle =
          userJob?.[
            String(user.CatGeschlechtID) === EGenders.FEMALE
              ? "BezeichnungWeiblichML"
              : "BezeichnungMaennlichML"
          ][certificate.CatSprache.ID]
        const userFullName = `${user.Vorname} ${user.Nachname}`

        let payload: Partial<ICertificate> | null = null

        if (certificate.SignatureABenutzerID === user.BenutzerID) {
          payload = {
            Funktion1: userJobTitle,
            Unterschrift1: userFullName,
          }
        }
        if (certificate.SignatureBBenutzerID === user.BenutzerID) {
          payload = {
            ...payload,
            Funktion2: userJobTitle,
            Unterschrift2: userFullName,
          }
        }

        if (payload) {
          updateCertificate.mutate({
            ...certificate,
            ...payload,
          })
        }
      }
    }
  }

  async function handleUpdateUserProperties(payload: Partial<IUser>) {
    try {
      const updatedUser = await updateUser(payload)

      void queryClient.invalidateQueries("USERS_INFINITE")

      if (updatedUser) {
        updateUsersCache(updatedUser)
        updateCertificateInView(updatedUser)
      }
    } catch (error) {
      // Send error to Sentry.
    }
  }

  return (
    <Drawer
      {...{ show, onClose }}
      position="left"
      narrow
      extraHeaderButtons={
        <Button
          secondary
          disabled={logoutUser.isLoading || inProgress !== "none"}
          Icon={LogoutIcon}
          onClick={onLogoutButtonClick}
          className="mr-auto"
        >
          {t("signOut")}
        </Button>
      }
    >
      <div className="drawerSection">
        <TextInput id="email" label={t("email")} {...{ register }} disabled />

        <TextInput
          id="firstName"
          label={t("firstName")}
          {...{ register }}
          register={register}
          onBlur={() =>
            handleUpdateUserProperties({
              Vorname: getValues("firstName"),
            })
          }
        />

        <TextInput
          id="lastName"
          label={t("lastName")}
          {...{ register }}
          onBlur={() =>
            handleUpdateUserProperties({
              Nachname: getValues("lastName"),
            })
          }
        />

        <div>
          <div className="labelTop">{tN("shared:gender")}</div>
          <RadioGroup
            defaultValue={currentUser && currentUser.CatGeschlechtID.toString()}
            onValueChange={(id) =>
              handleUpdateUserProperties({ CatGeschlechtID: Number(id) as 1 | 2 | undefined})
            }
          >
            <RadioItem
              htmlFor="profile-female"
              label={tN("shared:female")}
              value={EGenders.FEMALE} />
            <RadioItem
              htmlFor="profile-male"
              label={tN("shared:male")}
              value={EGenders.MALE} />
          </RadioGroup>
        </div>
      </div>

      <div className="drawerSection">
        <Select
          label={t("roles")}
          disabled
          preselectedItem={{
            value: mostPriviligedRole?.ID,
            primary: tN(
              `userRoles:${mostPriviligedRole?.Code as EUserRoleCodes}`
            ),
          }}
        />

        <SelectGeneric
          label={t("UILanguage")}
          options={languagesOptions}
          value={languagesOptions.find(
            (item) => item.value === currentUser?.CatSprache.ID
          )}
          onChange={async ({ value }) => {
            await updateUser({
              CatSprache: {
                ID: value,
                DisplayName: "",
              },
            })
            void i18n.changeLanguage(value as string)
            void queryClient.refetchQueries()
          }}
        />
      </div>
      <div className="drawerSection">
        <div className="flex">
          <div>
            <h2 className="mb-4">{t("profilePicture")}</h2>
            <label
              htmlFor="uploadProfilePicture"
              className="button buttonSecondary cursor-pointer"
            >
              <UploadIcon className="icon mr-2" />
              {t("uploadProfilePicture")}
            </label>
            <input
              type="file"
              accept=".jpeg,.jpg,.png"
              id="uploadProfilePicture"
              className="hidden"
              onChange={(event) => onProfilePictureSelect(event.target.files)}
            />
          </div>
          <div className="ml-auto self-center">
            {currentUser?.ProfileFileId && (
              <img
                crossOrigin="use-credentials"
                src={`${apiUrl}file/getUserPicture?userId=${currentUser.BenutzerID}`}
                alt="Current user"
                className={`
                  h-24 w-24
                  rounded-full
                  border border-gray-300 object-cover
                `}
              />
            )}

            {currentUser && !currentUser.ProfileFileId && (
              <div
                className={`
                  flex h-24 w-24
                  items-center justify-center
                  rounded-full
                  border border-gray-300 bg-gray-100
                  text-2xl
                `}
              >
                {currentUser.Vorname.charAt(0)} {currentUser.Nachname.charAt(0)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="drawerSection">
        <SelectGeneric
          label={t("function")}
          options={functionSelectItems}
          value={functionSelectItems.find(
            (item) => item.value === currentUser?.CatFunktionID
          )}
          onChange={({ value }) =>
            handleUpdateUserProperties({ CatFunktionID: value })
          }
        />
        {isHr && (
          <UserSelectOrgUnit
            disabled
            orgUnits={currentUser?.OrganizationUnits ?? []}
            onUpdate={(value) =>
              handleUpdateUserProperties({ OrganizationUnits: value })
            }
          />
        )}
        {/* Autocomplete kept filling up the org unit search input with users email
        Happens because password fields are there
        This prevents it. Autocoplete="off" and all of its variations didn't work*/}
        <input className="absolute opacity-0" />
      </div>
      <div className="drawerSection">
        <Toggle
          initiallyOn={currentUser?.IsStellvertreterVerantwHrAktiv}
          label={t("substitution")}
          onChange={async (on: boolean) => {
            setSubstitution(on)

            if (!on) {
              if (!getValues("responsibleHR")) return

              setValue("responsibleHR", undefined)

              if (currentUser) {
                await currentUserMutation.mutateAsync({
                  ...currentUser,
                  StellvertreterVerantwHrBenutzer: null,
                  IsStellvertreterVerantwHrAktiv: false,
                })

                void queryClient.refetchQueries(employeeKeys.all())
              }
            }
          }}
        />
        {substitution && (
          <SelectGeneric
            label={t("responsibleHR")}
            options={substitutionOptions}
            value={substitutionOptions.find(item => (
              item.value === currentUser?.StellvertreterVerantwHrBenutzer?.ID
            ))}
            onChange={async ({ value }) => {
              setValue("responsibleHR", value as number | undefined)

              await updateUser({
                StellvertreterVerantwHrBenutzer: {
                  ID: value,
                  DisplayName: "",
                },
                IsStellvertreterVerantwHrAktiv: true,
              })

              void queryClient.refetchQueries(employeeKeys.all())
            }}
          />
        )}
      </div>

      <div className="drawerSection flex items-center justify-between gap-2">
        <h2>{t("password")}</h2>
        <Button className="!mt-0" onClick={() => setPasswordModalOpen(true)}>
          {t("changePassword")}
        </Button>
      </div>

      {passwordModalOpen && (
        <PasswordModal
          open={passwordModalOpen}
          onOpenChange={setPasswordModalOpen}
        />
      )}
    </Drawer>
  )
}
