import { Combobox, Transition } from "@headlessui/react"
import classNames from "classnames"
import * as _ from "lodash-es"
import { ChangeEvent, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  ChevronUpDown as ChevronUpDownIcon,
  Checkmark as CheckmarkIcon,
} from "assets/icons"
import cn from "utils/classMerge"

import FloatPlacement from "./FloatPlacement"
import PillClickable from "./PillClickable"
import { SelectOption } from "./SelectGeneric"

interface Props<T> {
  options: SelectOption<T>[]
  value?: SelectOption<T>[]
  defaultValue?: SelectOption<T>[]
  onChange?: (values: SelectOption<T>[]) => void
  disabled?: boolean
  minSearchTermLength?: number
  label?: string | null
  placeholder?: string | null
}

const ComboboxGeneric = <T extends string | number>({
  options: optionsProp,
  onChange,
  disabled,
  minSearchTermLength = 0,
  label,
  value,
  placeholder,
}: Props<T>) => {
  const { t } = useTranslation("selectComponent")

  const [query, setQuery] = useState("")

  const options = useMemo(() => {
    if (query.length >= minSearchTermLength) {
      return optionsProp.filter((option) =>
        option.primary?.toLowerCase().includes(query.toLowerCase())
      )
    }
    return optionsProp
  }, [query, minSearchTermLength, optionsProp])

  return (
    <Combobox
      as="div"
      value={value}
      onChange={onChange}
      disabled={disabled}
      multiple
      by="value"
    >
      {label && (
        <Combobox.Label
          className={classNames(["mb-2 block text-sm font-bold text-black"])}
        >
          {label}
        </Combobox.Label>
      )}

      <FloatPlacement>
        <div className="relative rounded-md bg-white">
          <Combobox.Input
            displayValue={() => query}
            placeholder={placeholder ?? t("placeholder") ?? ""}
            className={cn([
              "h-10 w-full rounded-md border-none",
              "py-2 pl-3 pr-10 shadow-sm ",
              "ring-1 ring-inset ring-black",
              "focus:outline-none focus:ring-inset focus:ring-2 focus:ring-ocean",
              disabled &&
                "pointer-events-none select-none bg-gray-200 opacity-50",
            ])}
            onChange={_.debounce(
              ({ target }: ChangeEvent<HTMLInputElement>) => {
                setQuery(target.value)
              },
              300
            )}
          />

          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md pr-3 focus:outline-none">
            <ChevronUpDownIcon className="icon" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Combobox.Options
          key={value?.length}
          className={classNames([
            "z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg",
            "focus:outline-none border border-black",
          ])}
        >
          {options.length === 0 && (
            <div className="px-4 pb-2 pt-3">{t("nothingFound")}</div>
          )}
          {options.map((option) => (
            <Combobox.Option
              key={`${option.value}-key`}
              value={option}
              className={({ active, selected }) => cn(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                (active || selected) && "bg-clearSky"
              )
            }
            >
              {({ selected }) => (
                <>
                  <div className="flex items-center">
                    <span
                      className={` truncate ${selected ? "font-semibold" : ""}`}
                    >
                      {option.primary}
                    </span>
                  </div>
                  {selected && (
                    <span
                      className={classNames([
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                        "text-black",
                      ])}
                    >
                      <CheckmarkIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </FloatPlacement>

      <Transition show={!!value?.length}>
        <div className="-mt-2 flex flex-wrap gap-2 rounded-b-lg border border-black bg-ice p-2 pt-4">
          {value?.map((item) => (
            <PillClickable
              disabled={disabled}
              key={`${item.value}-key`}
              label={item.primary ?? ""}
              onClick={() => {
                onChange?.(value.filter((i) => i.value !== item.value))
              }}
              close
            />
          ))}
        </div>
      </Transition>
    </Combobox>
  )
}

export default ComboboxGeneric
