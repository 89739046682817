/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Configuration, PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import axios, { AxiosError } from "axios"
import * as _ from "lodash-es"
import { AppProps } from "next/app"
import Script from "next/script"
import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { Toaster, toast } from "react-hot-toast"
import { QueryClient, QueryClientProvider, useIsFetching } from "react-query"
// @ts-ignore
import { ReactQueryDevtools } from "react-query/devtools"
// import { ToastContainer, } from 'react-toastify';
// import "react-toastify/dist/ReactToastify.css"

import { ConfirmDialogProvider } from "@/components/elements/ConfirmContext/ConfirmProvider"
import { TopBanner } from "@/components/elements/TopBanner"
import Wrapper from "@/components/layouts/Wrapper"
import WithMainNavigationLayout from "components/layouts/WithMainNavigation"
import { AppContextProvider } from "context/AppContext"
import { CurrentUserProvider } from "context/CurrentUserContext"
import { DownloadProvider } from "context/DownloadContext"
import { SidebarProvider } from "context/SidebarContext"
import { TSystemInfo } from "hooks/data"
import { LOCAL_STORAGE_KEYS, EUserRoles } from "src/constants"
import { IUser } from "types"
import { fetchJson, isDevEnv, isTestEnv } from "utils/network"

import "translations/i18n"

import "../styles/globals.scss"

const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY as string

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const msalConfig: Configuration = {
  auth: {
    clientId: "",
    authority: "",
    redirectUri: "/",
    postLogoutRedirectUri: "/public/login",
  },
}

function LoadingIndicator() {
  const queriesInProgress = useIsFetching()

  useEffect(() => {
    if (queriesInProgress > 0) {
      document.body.style.cursor = "progress"
    } else {
      document.body.style.cursor = "auto"
    }
  }, [queriesInProgress])

  return null
}

function MyApp(props: AppProps): ReactNode {
  const {
    Component,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pageProps,
    router,
  } = props

  const { route, asPath } = router
  const isPublicPage = route.startsWith("/public")

  useEffect(() => {
    const { analytics } = window
    const pageName = document.title.split("-")[1]

    if (analytics && pageName) {
      void analytics.page(
        "Page view",
        route === "/certificates/[id]/edit"
          ? `Certificate ID#${asPath.split("/")[2]}`
          : pageName.trim()
      )
    }
  }, [route, asPath])

  const [isUser, setIsUser] = useState(false)
  const [isTechAdmin, setIsTechAdmin] = useState(false)
  const [isHr, setIsHr] = useState(false)
  const [isSuperHr, setIsSuperHr] = useState(false)
  const [authorized, setAuthorized] = useState(false)
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>(
    new PublicClientApplication(msalConfig)
  )
  const [systemInfo, setSystemInfo] = useState<TSystemInfo>()
  const [redirectPathAfterLogin, setRedirectPathAfterLogin] = useState("")

  useEffect(() => {
    const jwtToken = localStorage.getItem(
      LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN
    )
    if (jwtToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
    }

    if ("serviceWorker" in navigator && isDevEnv) {
      window.addEventListener("load", () => {
        navigator.serviceWorker
          .register("/sw.js", { scope: "/" })
          .then((registration) => {
            console.log(
              "Service worker registration successful 😎 Scope: ",
              registration.scope
            )
          })
          .catch((error) => {
            console.log("ServiceWorker registration failed 😒", error)
          })
      })
    }
  }, [])

  useEffect(() => {
    console.log('🔥 x9')
  }, [])
  useEffect(() => {
    if (isHr || isSuperHr || isTechAdmin) {
      setAuthorized(true)
    } else if (isUser) {
      if (route.startsWith("/certificates")) {
        setAuthorized(true)
      } else if (!isPublicPage) {
        void router.replace("/certificates")
      }
    }
    // TEMP:
    // The intention here is to split this role off completely.
    // Doing so requires some rearrangment on the server.
    // else if (isTechAdmin) {
    //   if (route.startsWith("/employees") || route.startsWith("/admin") || route.startsWith("/user-management")) {
    //     setAuthorized(true)
    //   } else if (!isPublicPage) {
    //     void router.replace("/employees")
    //   }
    // }
  }, [router, route, isPublicPage, isUser, isTechAdmin, isHr, isSuperHr])

  const handle401 = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_CERTIFICATES)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_EMPLOYEES)
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS_USERS)

    if (isTestEnv || isDevEnv) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SKRIBA_LOCAL_DEV_TOKEN)
      axios.defaults.headers.common.Authorization = ""
    }

    setRedirectPathAfterLogin(
      router.asPath === "/" ? "/certificates" : router.asPath
    )
    const loginUrl = new URL("/public/login", window.location.origin)

    // transfer the query params to the login page
    loginUrl.search = window.location.search
    void router.push(loginUrl)
  }, [router])

  useEffect(() => {
    if (isPublicPage) {
      // @ts-ignore
      void fetchJson<TSystemInfo>("system").then((data) => {
        const { AzureAdAuthority, AzureAdClientId } = data

        setMsalInstance(
          new PublicClientApplication({
            ...msalConfig,
            auth: {
              ...msalConfig.auth,
              clientId: AzureAdClientId,
              authority: AzureAdAuthority,
            },
          })
        )
        setSystemInfo(data)
      })
    }

    if (!isPublicPage) {
      void fetchJson<IUser>("benutzer/current", {
        queryParams: {
          includeAuthorizationInfo: false,
        },
      })
        .then(({ SysRoles }) => {
          const roleIds = SysRoles.map(({ ID }) => ID)

          setIsUser(roleIds.length === 1)
          setIsHr(roleIds.length === 2 && roleIds.includes(EUserRoles.HR))
          setIsSuperHr(roleIds.length === 4)
          setIsTechAdmin(
            roleIds.length === 2 && roleIds.includes(EUserRoles.TECH_ADMIN)
          )
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 401) {
            handle401()
          }
        })
    }
  }, [isPublicPage, handle401])

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: twentyFourHoursInMs,
            retry(failureCount, error) {
              const { response } = error as AxiosError
              if (
                response?.status === 403 &&
                response?.statusText === "Forbidden"
              ) {
                return false
              }
              return failureCount < 3
            },
            // NOTE:
            // Could be used to optimize rendering.
            // https://tkdodo.eu/blog/react-query-render-optimizations#tracked-queries
            // notifyOnChangeProps: "tracked",
            onError(error) {
              if ((error as AxiosError).response?.status === 401) {
                handle401()
              }
            },
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ConfirmDialogProvider>

        <AppContextProvider>
          {segmentWriteKey && (
            <Script id="segment">
              {`
                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.15.2";
                  analytics.load("${segmentWriteKey}");
                  analytics.page();
                }}();
              `}
            </Script>
          )}
          <LoadingIndicator />

          <MsalProvider instance={msalInstance}>
            <GoogleOAuthProvider clientId="975346567909-38afadpud1d3f5khv1pjd41d7gvtqgsj.apps.googleusercontent.com">
              {isPublicPage && (
                <Component
                  {...{
                    systemInfo,
                    redirectPathAfterLogin,
                    ...pageProps,
                  }}
                />
              )}
              {!isPublicPage && authorized && (
                <CurrentUserProvider>
                  <TopBanner />
                  <DownloadProvider>
                    <SidebarProvider>
                      <WithMainNavigationLayout>
                        <Wrapper>
                          <Component {...pageProps} />
                        </Wrapper>
                      </WithMainNavigationLayout>
                    </SidebarProvider>
                  </DownloadProvider>
                </CurrentUserProvider>
              )}
              <ReactQueryDevtools initialIsOpen={false} />
            </GoogleOAuthProvider>
          </MsalProvider>

          {/* <ToastContainer
            pauseOnFocusLoss={ false }
            position="top-center"
            limit={2}
            autoClose={3000}
          /> */}

          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={6}
            containerClassName=""
            toastOptions={{
              className: "",
              success: {
                duration: 1500,
                className: "zzz",
                iconTheme: {
                  // green-400 (from config)
                  primary: "hsl(112 64% 52%)",
                  secondary: "white",
                },
              },
              error: {
                duration: 2000,
                iconTheme: {
                  // red-400 (default)
                  primary: "#f87171",
                  secondary: "white",
                },
              },
              loading: {
                iconTheme: {
                  // inner spinning element, blue-600 (from config)
                  primary: "hsl(220 99% 54%)",
                  // ring, blue-200 (from config)
                  secondary: "hsl(213 97% 87%)",
                },
              },
            }}
          />
        </AppContextProvider>
      </ConfirmDialogProvider>
    </QueryClientProvider>
  )
}

export default MyApp
