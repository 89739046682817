import { AlertTriangleIcon, X } from "lucide-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cn } from "utils"

export const TopBanner = () => {
  const { t } = useTranslation('general')
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    const alertKey = "SKRIBA_TOP_ALERT_CLOSED"
    const debugKey = "SKRIBA_TOP_ALERT_DEBUG"

    const alertClosed = localStorage.getItem(alertKey)
    const debugMode = localStorage.getItem(debugKey) === "true"

    const now = new Date()
    // Zurich time, just change the date as needed
    const targetDate = new Date("2024-06-13T11:00:00+02:00")
    const endDate = new Date("2024-06-13T14:00:00+02:00")

    if (
      (!alertClosed &&
        now >= targetDate &&
        now <= endDate) ||
      debugMode
    ) {
      setShowAlert(true)
    }
  }, [])

  const closeAlert = () => {
    setShowAlert(false)
    localStorage.setItem("SKRIBA_TOP_ALERT_CLOSED", "true")
  }

  if (!showAlert) {
    return null
  }

  return (
    <div className="bg-yellow-100 text-yellow-700 p-2 relative top-0 w-full flex items-center justify-center gap-2">
      <AlertTriangleIcon className="h-4 w-4" />
      <p>{t("top-banner.text")}</p>
      <button
        className={cn(
          "flex items-center rounded-sm opacity-70 ring-offset-background ml-1",
          "transition-opacity hover:opacity-100 focus:outline-none",
          "focus:ring-2 focus:ring-ring focus:ring-offset-2",
          "disabled:pointer-events-none",
          "data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        )}
        onClick={closeAlert}
      >
        <X className="h-4 w-4" />
      </button>
    </div>
  )
}
